.indicator {
    display: flex;
    justify-content: center;
    text-align: center;
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    margin: 0 auto;
    user-select: none;
}

.indicator b {
    align-self: center
}