@import url("https://fonts.googleapis.com/css2?family=Source+Code+Pro");
/*
 *  Header
 */

*{
    font-family: 'Source Code Pro', monospace;
}

html body{
  max-width: 100%;
  overflow-x: hidden;
}


html::-webkit-scrollbar {
  width: 12px;
  background-color: #2f3e46;
}
 
html::-webkit-scrollbar-thumb {
  border-radius: 10px;
  -webkit-box-shadow: inset 0 0 6px #354f52;
  background-color: #354f52;
}

img{
  max-width:100%;
}

.header-text{
    color: white;
}

.header-text .name{
    color: #84a98c;
}

/*
 *  Navigation 
 */
.sticky {
    top: 0;
    z-index: 999;
}

.nav-link {
  color: #fff !important;
}

.nav-link:hover{
  transition-delay: 0.1s;
  color: #84a98c !important;
  font-size: 20px;
}

.navbar-brand {
    font-weight: 500;
    padding-top: 12px;
    padding-bottom: 12px;
}

.navbar-nav .nav-link {
    padding: 14px 10px;
}

@media (min-width: 576px) {
    .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: 12px;
        padding-right: 12px;
    }
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-nav .nav-link {
        padding-left: 14px;
        padding-right: 14px;
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 16px;
        padding-right: 16px;
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl .navbar-nav .nav-link {
        padding-left: 16px;
        padding-right: 16px;
    }
}
/*-------------------------------------
        Skills
--------------------------------------*/
#skills{
  background-color: #2f3e46;
}

#skills hr{
  background-color: #354f52;
}

/*-------------------------------------
        My way
--------------------------------------*/
#my-way{
  background-color: #354f52;
  padding: 15%;
}

/*-------------------------------------
        Certifications
--------------------------------------*/
#my-certifications{
  background-color: #2f3e46;
  padding: 15%;
}

/*-------------------------------------
        Portfolio
--------------------------------------*/
#portfolio{
  background-color: #354f52;
}

#portfolio .card {
  background-color: #31484b;
  border: #31484b;
}

/*-------------------------------------
        About
--------------------------------------*/
.about {
  padding: 50px 0 100px;
  background-color: #354f52;
}
/*.single-about-txt*/
.single-about-txt h3 {
    font-size:18px;
    color: #ffffff;
    text-transform: initial;
    line-height: 1.8;
}
.single-about-txt h6 {
  font-size: 14px;
  color: #ffffff;
  text-transform: initial;
  line-height: 1.8;
  text-align: justify;
}
.single-about-txt p {
    font-size: 16px;
    color: #c2c2c2;
    padding: 35px 0 43px;
    border-bottom: 1px solid #ffffff;
}
.single-about-add-info {
    margin: 35px 0;
}
.single-about-add-info h3 {
    color: #ffffff;
    font-size: 16px;
    text-transform: capitalize;
}
.single-about-add-info p {font-weight:  300;border:  0;padding: 0;margin-top: 3px;}

/*.single-about-img */
.single-about-img{
    position: relative;
    background-color: #fff;
    border-radius: 20px 20px;
    
}
.single-about-img img{
    margin-left: auto;
    margin-right: auto;
    display: block;
    max-width: 80%;
}
.about-list-icon{
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 60px;
    background: #ffffff;
    border-radius: 20px 20px;
}

/*.ab-list-icon */
.about-list-icon ul {
    display: flex;
    list-style: none;
    margin: auto;
}
.about-list-icon ul li a {
    list-style: none;
    cursor: pointer;
    color: #354f52;
    display: inline-block;
    border: 2px solid;
    margin: 10px;
    border-radius: 8px;
    width: 30px;
    height: 30px;
    line-height: 28px;
    text-align: center;
}

.about-list-icon ul li a:hover {
  color: #fff;
  background-color: #354f52;
}

/* Page */
.vg-page {
    position: relative;
    display: block;
    padding: 80px 0;
    width: 100%;
    overflow: hidden;
  }
  
  .page-home {  
    padding: 25px 0;
    width: 100%;
    height: 700px;
    z-index: 99;
    background-image: url("../img/header.png")
  }
  
  .page-home::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #2f3e46c9;
    z-index: -1;
  }
  
  .page-home .navbar {
    position: fixed;
    /*top: auto;*/
    left: 0;
    width: 100%;
    padding-top: 6px;
    padding-bottom: 6px;
    background-color: #354f52;
    z-index: 99;
  }
  
  .page-home .navbar.floating {
    top: 0;
    background-color: #343a40;
  }
  
  .page-home .caption-header {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 100%;
    color: #fff;
    z-index: 10;
  }
  
  .page-home h1 {
    font-size: 50px;
  }
  
  .page-home .badge {
    padding: 16px 24px;
    background-color: #354f52c3;
    color: #84a98c;
    text-shadow: 0 2px 4px rgba(23, 25, 37, 0.4);
    font-size: 16px;
    border-radius: 40px;
  }
  
  .page-home .floating-button {
    position: absolute;
    bottom: 20px;
    left: 0;
    right: 0;
    margin: auto;
    width: 45px;
    height: 45px;
    line-height: 45px;
    text-align: center;
    border-radius: 50%;
    color: #fff;
    font-size: 21px;
    cursor: pointer;
    z-index: 10;
  }
  
  .timeline {
    position: relative;
    display: block;
    list-style: none;
    padding-left: 0;
    width: 100%;
  }
  
  .timeline::before {
    content: '';
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    margin: auto;
    width: 2px;
    height: 95%;
    background-color: #e3e5e9;
  }
  
  .timeline li {
    position: relative;
    display: block;
    padding-left: 30px;
    margin-bottom: 20px;
    
  }
  
  .timeline li::before {
    content: '';
    position: absolute;
    top: 8px;
    left: -8px;
    width: 18px;
    height: 18px;
    background-color: #2f3e46;
    border: 3px solid #fff;
    border-radius: 50%;
  }
  
  .timeline li .title {
    width: 150px;
    padding: 6px 20px;
    background-color: #cad2c5;
    color: #2f3e46;
    font-weight: 500;
    border-radius: 30px 30px 30px 30px;
  }
  
  .timeline li .details {
    background-color: #31484b;
    margin-top: 8px;
    padding: 20px;
    border-radius: 4px;
    color: #c2c2c2;
  }

  .timeline li h5 {
    color: #ffffff;
  }
  
  .timeline li .details h5,
  .timeline li .details p {
    margin-bottom: 0;
  }
  
  .timeline li .details small {
    display: block;
    font-weight: 500;
    font-size: 14px;
    margin-top: 5px;
    margin-bottom: 10px;
  }
  
  /* Footer */
  .vg-footer {
    position: relative;
    display: block;
    margin: 0;
    padding: 50px 0;
    background-color: #2f3e46;
    color: #cad2c5;
  }
  .footer-info {
    max-width: 350px;
  }